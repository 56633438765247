<template>
  <div class="results-modal">
    <div class="results-modal__header">
      <div class="results-modal__header-title">
        <span class="icon">
          <img src="@/assets/img/results-modal/icon.svg" alt="tag" />
        </span>
        <span class="text"> {{ this.titles.head }} </span>
      </div>
      <button class="close" @click="close">
        <img src="@/assets/img/results-modal/close.svg" alt="❌" />
      </button>
    </div>
    <div class="results-modal__body">
      <div class="results-modal__body-title" v-html="titles.body"></div>
      <ul class="results-modal__body-items" v-if="isShowItems">
        <result-item
          v-for="item in items"
          :id="item.id"
          :name="item.name"
          :key="item.id"
          @selectStation="selectStation"
        >
          <template #distance>
            {{ item.distance }}
          </template>
        </result-item>
      </ul>
    </div>
  </div>
</template>

<script>
import ResultItem from '@/components/dump/results-modal/ResultItem'
const titles = {
  notFound: {
    head: 'В радиусе 100 км от указанного адреса метеостанций не найдено',
    body: 'Пожалуйста, выберите другой адрес'
  },
  found: {
    head: 'В радиусе 100 км от указанного адреса найдено несколько метеостанций',
    body: 'Выберите ближайший <br> населенный пункт'
  }
}
export default {
  components: { ResultItem },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  name: 'ResultsModal',
  methods: {
    close() {
      this.$emit('close')
    },
    selectStation(data) {
      this.$emit('getStationData', data)
      this.close()
    }
  },
  computed: {
    titles() {
      return this.isShowItems ? titles.found : titles.notFound
    },
    isShowItems() {
      return this.items.length > 0
    }
  }
}
</script>

<style scoped lang="sass">
.results-modal
  +media((width: (320: 90vw, 360: 80vw)))
  max-width: rem(528)
  background: $white
  border-radius: rem(4)
  box-shadow: $Drop_Shadow_Panel_A
  overflow: hidden
  &__header
    background-image: url('~@/assets/img/results-modal/header.jpg')
    background-size: cover
    background-position: center center
    +media((padding: (320: rem(21) 10vw rem(21) rem(20), 576: rem(32) rem(72))))
    position: relative
    &-title
      display: flex
      align-items: center
      .icon
        +media((margin-right: (320: rem(20), 576: rem(34))))
      .text
        @extend %14
        color: $white
        font-weight: bold
    .close
      height: rem(40)
      width: rem(40)
      cursor: pointer
      position: absolute
      right: 0
      top: 0
      @extend %clear-btn
  &__body
    +media((padding: (320: 0 5vw, 576: 0 rem(48))))
    &-title
      @extend %18
      color: $black_A
      font-weight: bold
      +media((margin: (320: rem(24) 0 rem(16), 576: rem(24) 0 rem(22))))
      +media((padding: (576: 0 rem(24))))
    &-items
      list-style: none
      +media((margin: (320: rem(16) 0 rem(12), 576: rem(22) 0 rem(40))))
      padding: 0
</style>
