<template>
  <div class="map-wrapper" :class="{ 'map-wrapper--collapsed': isMapCollapsed }" ref="mapWrapper">
    <div id="map" class="map"></div>
    <simple-btn
      color="red"
      @btnClicked="toggleMapHeight"
      v-if="isShowToggle"
      :class="{ active: !isMapCollapsed }"
    >
      <template #icon>
        <img src="@/assets/img/button/chevron-down.svg" alt="↓" />
      </template>
    </simple-btn>
    <Modal :is-modal-open="isModalOpen" @close="toggleModal">
      <results-modal
        :items="pointsToModal"
        @close="toggleModal"
        @getStationData="getStationDataFromModal"
      />
    </Modal>
  </div>
</template>

<script>
// import { cities } from '@/utils/cities'
import { clone } from 'ramda'
import { mapState } from 'vuex'
import {
  getFirstElementsByCount,
  numberFormatting,
  sortAscendingArrOfObjects,
  zonePointGeometry,
  zonePointPreset,
  zoomOptions,
  searchControlOptions,
  commonMapOptions,
  getPlaceMarkCaption
} from '@/utils'
import Modal from '@/components/dump/Modal'
import ResultsModal from '@/components/dump/results-modal/ResultsModal'
import SimpleBtn from '@/components/dump/Page-elements/SimpleBtn'

const greenColor = '#2ECC71'

const searchRadius = 10e4

const circleOptions = {
  fillColor: greenColor,
  fillOpacity: 0.5,
  strokeWidth: 0
}

const mapOptions = {
  center: commonMapOptions.center,
  zoom: commonMapOptions.zoom,
  controls: []
}

export default {
  props: {
    tableHeaderData: {
      type: Object,
      default: () => ({}),
      required: true
    },
    isMapCollapsed: {
      type: Boolean,
      required: true
    },
    isShowToggle: {
      type: Boolean,
      required: true
    }
  },
  name: 'YaMapMarkers',
  components: { SimpleBtn, ResultsModal, Modal },
  data: () => ({
    myMap: null,
    isCircleAdded: false,
    isModalOpen: false,
    pointsToModal: [],
    mapObjects: [],
    isShowMap: false
  }),
  watch: {
    isMapCollapsed() {
      setTimeout(() => {
        this.myMap.container.fitToViewport()
      })
    }
  },
  methods: {
    toggleModal() {
      this.isModalOpen = !this.isModalOpen
    },
    toggleMapHeight() {
      this.$emit('toggleMapHeight', !this.isMapCollapsed)
    },
    initMap() {
      this.myMap = new ymaps.Map('map', mapOptions, zoomOptions)
      window.zonePoint = new ymaps.GeoObject(zonePointGeometry, zonePointPreset)
      // const searchControl = myMap.controls.get('searchControl')
      const searchControl = new ymaps.control.SearchControl({
        options: searchControlOptions
      })
      this.myMap.controls.add(searchControl)
      this.myMap.geoObjects.add(window.zonePoint)
      const circle = new ymaps.Circle([null, searchRadius], null, circleOptions)
      // create deep copy of cities array, coz Vue add observers (getters and setters)
      // on each city coordinates array.
      // destructing ({ coordinates: [...coordinates], id, name }) make same thing, but longer
      const cities = clone(this.cities)
      // let time = performance.now()
      const mapObjects = cities.map(({ coordinates, id, name }) => {
        const placeMark = new ymaps.Placemark(coordinates, {
          data: { name, id }
        })
        placeMark.events.add('click', () => {
          const { id, name } = placeMark.properties.get('data')
          const stationCoords = placeMark.geometry.getCoordinates()
          this.computedTableHeaderData.mapFeature = name
          this.computedTableHeaderData.userAddress = `${name}, (${stationCoords})`
          this.highlightPlaceMark(placeMark, stationCoords, true)
          window.zonePoint.properties.set({
            iconCaption: name
          })
          this.getStationData(id)
        })
        return placeMark
      })
      // time = performance.now() - time
      // console.log('Время выполнения = ', time)
      this.mapObjects = ymaps.geoQuery(mapObjects)
      this.myMap.geoObjects.add(this.mapObjects.clusterize())
      this.addSearchControlEvent(searchControl, circle)
    },
    findObjectsInCircle(userCoords, circle) {
      const objectsInsideCircle = this.mapObjects.searchInside(circle)
      objectsInsideCircle.setOptions('preset', 'islands#redIcon')
      let foundPoints = []
      objectsInsideCircle.each(p => {
        const objectCoords = p.geometry.getCoordinates()
        const { id, name } = p.properties.get('data')
        const distance = numberFormatting(
          ymaps.coordSystem.geo.getDistance(userCoords, objectCoords) / 1000,
          2
        )
        const pointData = { objectCoords, id, name, distance }
        foundPoints.push(pointData)
      })
      this.mapObjects.remove(objectsInsideCircle).setOptions('preset', 'islands#blueIcon')
      const filteredByDistance = sortAscendingArrOfObjects(foundPoints, 'distance')
      this.pointsToModal = getFirstElementsByCount(filteredByDistance, 3)
      if (this.pointsToModal.length === 1) {
        this.computedTableHeaderData.mapFeature = this.pointsToModal[0].name
        this.getStationData(this.pointsToModal[0].id)
      } else {
        this.toggleModal()
      }
    },
    addSearchControlEvent(searchControl, circle) {
      searchControl.events.add('resultshow', e => {
        const target = searchControl.getResultsArray()[e.get('index')]
        const coords = target.geometry.getCoordinates()
        const name = target.properties.get('text')
        this.computedTableHeaderData.userAddress = `${name}, (${coords})`
        circle.geometry.setCoordinates(coords)
        if (!this.isCircleAdded) {
          this.myMap.geoObjects.add(circle)
          this.isCircleAdded = true
        }
        this.findObjectsInCircle(coords, circle)
        this.highlightPlaceMark(target, coords)
      })
    },
    highlightPlaceMark(target, coords, isFromPlaceMark = false) {
      window.zonePoint.geometry.setCoordinates(coords)
      window.zonePoint.options.set('iconColor', greenColor)
      if (!isFromPlaceMark) {
        this.setPlaceMarkCaption(target)
      }
    },
    setPlaceMarkCaption(obj) {
      window.zonePoint.properties.set({
        iconCaption: getPlaceMarkCaption(obj)
      })
    },
    getStationData(id) {
      this.$emit('requestMapResults', { id: id, tableHeaderData: this.computedTableHeaderData })
      if (!this.isShowToggle) {
        this.$emit('showToggle')
      }
      this.$emit('toggleMapHeight', true)
    },
    getStationDataFromModal(data) {
      const { id, name } = data
      this.computedTableHeaderData.mapFeature = name
      this.getStationData(id)
    }
  },
  mounted() {
    ymaps.ready(this.initMap)
    // this.initMap()
  },
  beforeDestroy() {
    delete window.zonePoint
    this.myMap.destroy()
  },
  computed: {
    // cities: () => cities,
    ...mapState({
      cities: state => state.mapData.data
    }),
    computedTableHeaderData() {
      return { ...this.tableHeaderData }
    }
  }
}
</script>

<style scoped lang="sass">
.map
  &-wrapper
    @extend %map-wrapper
    &--collapsed
      .map
        height: rem(256)
</style>
