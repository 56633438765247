<template>
  <li class="item" @click="selectStation">
    <span class="item__icon">
      <img src="@/assets/img/results-modal/station-icon.svg" alt="tag" />
    </span>
    <span class="item__title">
      {{ name }}
    </span>
    <span class="item__distance"><slot name="distance"></slot> км</span>
    <span class="item__chevron">
      <img src="@/assets/img/results-modal/chevron.svg" alt="→" />
    </span>
  </li>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    }
  },
  methods: {
    selectStation() {
      this.$emit('selectStation', { id: this.id, name: this.name })
    }
  },
  name: 'ResultItem'
}
</script>

<style scoped lang="sass">
.item
  display: flex
  align-items: center
  min-height: rem(56)
  padding: rem(12) 0
  box-sizing: border-box
  +media((margin: (576: 0 rem(24))))
  transition: .5s
  cursor: pointer

  &:not(:last-child)
    box-shadow: inset 0 rem(-1) 0 $cool_B
  @media( any-hover: hover )
    &:hover
      margin: 0
      box-shadow: $Drop_Shadow_Panel_A
      border-radius: rem(4)
      padding: rem(12) 0 rem(12) rem(24)
      .item
        &__icon
          display: none
        &__chevron
          display: flex
          justify-content: center
        &__title
          color: $black_A
  &__icon
    +media((margin-right: (320: rem(10), 576: rem(12))))
  &__title, &__distance
    font-size: 12px
    line-height: 150%
    font-weight: 500
    color: $black_B
  &__title
    padding-right: rem(10)
  &__distance
    margin-left: auto
    font-weight: bold
    min-width: rem(60)
  &__chevron
    margin-left: rem(20)
    width: rem(36)
    display: none
</style>
